import React from "react"
import { Link } from "gatsby"
import "../assets/fonts/font.css"
import { connect } from "react-redux"
import Footer from '../components/footer';
import NewsLatter from '../components/newsletter';
import ShoppingContainer from '../components/shoppingcontainer';
import * as actionCreators from './../store/actions/index'
import phoneIcon from "../images/phone-icon.svg"
import chatIcon from "../images/chat-icon.svg"
import lineImage from "../images/line2.png"
import lineLeft from "../images/left-line.svg"
import lineRight from "../images/right-line.svg"
import Layout from "../components/layout"
import icon from "../images/vehical-info.svg"
import icon1 from "../images/your-info.svg"
import icon2 from "../images/customize-plan.svg"
import icon3 from "../images/create-account.svg"
import icon4 from "../images/payment-info.svg"
import icon5 from "../images/confirm-icon.svg"
import statvalsvg from "../images/claims/Stasts-value.svg"
import staticonsvg from "../images/claims/Stats-Icon.svg"


import icon6 from "../images/repair1-icon.svg"
import icon7 from "../images/repair2-icon.svg"
import icon8 from "../images/repair3-icon.svg"
import icon9 from "../images/repair4-icon.svg"
import icon10 from "../images/repair5-icon.svg"
import image1 from '../images/covered/core.png';
import image2 from '../images/covered/comfort.png';
import image3 from '../images/covered/convenience.png';

import newsec1 from "../images/newsec/car.png"
import newsec2 from "../images/newsec/umber3.png"

import SEO from "../components/seo"
import mobileBanner from "../images/service-contract-mobile.svg"
import FactoryPopup from "../components/popupFactory";
import PopupStacks from "../components/popupStacks";
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupShow: false, 
      bestPricePopup: false,
      stacksPopup: false
    }
    if(props.location && props.location.state && props.location.state.isLogout){
      props.logout();
    }
  }

  render() {
    const {
      props
    } = this;

    return (
      <Layout rest={props} layoutType="main">
        <SEO title="Servicecontract.com - Home" keywords={[`warranty`, `online extended warranty`, `extended warranty`, `car warranty`]} />
       
        <section className="simpbaner">
            <div className="siminer">
              <div className="simprow">
              <h1 className="simptitle">We’re Changing the Way You <span>Protect</span> Your Vehicle</h1>
              </div>
              <div className="simprow"><img src={mobileBanner} alt="" className="mobaner" /></div>
              <div className="simprow"><p className="simpdesc">Ultimate flexibility and the lowest prices, entirely online.</p></div>
              <Link to="/vehicleInfo/" className="btn primary get-price">Get your Quote</Link>
            </div>
        </section>

        

        <section className="newsec20">
        <div className="container">
          <div className="boxcenter2">
            
            
            <h3>Finding the right coverage has never been easier!</h3>
            <p className="titles">
            We offer unique month-to-month subscription contracts with no long-term obligation. Just select your 
            <Link to="/protect"> preferred coverage </Link>and deductible and continue driving worry-free!
            </p>
            <img src={newsec1} width="50%" />
          </div>
          <div className="boxcenter2 lintop">
          <img src={newsec2} width="75%" /><br /><br />
            <h3>Customize your perfect plan.</h3>
            <p className="titles">
            With a range of coverage and deductible options, you are in complete control of customizing your plan:
            <ul className="newul">

            <li>Deductibles ranging from $0 to $250</li>
            <li>Vehicle Service Contracts for new and pre-owned vehicles, with levels ranging from Powertrain to Comprehensive, 
              now including the <Link to="protect">Stacks Reward Plan!</Link></li>
            <li><Link to="protect/#lease">Lease Care Ultimate</Link> Vehicle Service Contract specifically for leased vehicles</li>
            <li>Complimentary <Link to="protect/#theft">ID Theft Protection</Link> (a $35 value) </li>
            <li>Optional <Link to="protect/#tirewheel">Tire & Wheel</Link> Protection</li>
            </ul>
            What’s best for you is best for us. It’s that easy.

            </p>
            
          </div>
          </div>
        </section>

        {/* --------------- process-work-container--------- */}
        <section className="process-work-container">
          <div className="container">
            <h3 className="title">How the Process Works</h3>
            <p  className="subtitle">Our customized process allows you to buy coverage specifically for your needs and driving habits.</p>
            <div className="work-content">
              <div className="card first">
                <div className="header">
                  <span> <img src={icon} alt="Logo" /></span>

                </div>
                <h4>Vehicle Info</h4>
                <p>Providing your vehicle’s year, make, model, and mileage helps us narrow down your options.</p>
              </div>
              {/* <div className="card">
                <div className="header">
                  <span> <img src={icon1} alt="Logo" /></span>
                </div>
                <h4>Your Info</h4>
                <p>Providing us your email and phone number (optional) allows you to save your quote and come back later.</p>
              </div> */}
              <div className="card">
                <div className="header">
                  <span> <img src={icon2} alt="Logo" /></span>
                </div>
                <h4>Customize Your Plan</h4>
                <p>We help you customize the plan to best fit your needs, driving habits, and budget.</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon3} alt="Logo" /></span>
                </div>
                <h4>Create Account</h4>
                <p>Creating an account gives you the option to save multiple quotes and then buy your plan.</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon4} alt="Logo" /></span>
                </div>
                <h4>Payment Info</h4>
                <p>Provide your payment information and select the terms that best fit your needs. We’ll do the rest!</p>
              </div>
              {/* <div className="card last">
                <div className="header">
                  <span> <img src={icon5} alt="Logo" /></span>
                </div>
                <h4>Confirm</h4>
                <p>Confirm your information and make sure you’ve selected the right plan.  We’ll do the rest!</p>
              </div> */}
            </div>
            {/* <div className="slider-point">
              <span className="active"></span>
              <span></span>
              <span></span>
            </div> */}
            <div className="btn-container">
              <a className="btn btn-outline" href={`tel:${'18008003629'}`}><img src={phoneIcon} alt="Logo" /> Call us</a>
              {/* <button className="btn btn-outline"><img src={phoneIcon} alt="Logo" /> Call us</button> */}
              {/* <button >Get Your Price</button> */}
              <Link className="btn primary get-price" to="/vehicleInfo/">Get Your Quote</Link>
              <Link className="btn btn-outline" to="/contact"><img src={chatIcon} alt="Logo" /> Contact Us</Link>
            </div>
          </div>

        </section>

        

        <section className="claimsStats-container">
          <div className="container">
              <h3 className="title">Typical Repair Costs</h3>
              <div className="claims-stat-img">
                  {/* <div className="claims-stat-svg">
                  </div> */}
                  <img src={statvalsvg} alt="info" />
              </div>
              <div className="claim-stat-term">
                  <div className="claim-stat-term-img"><img src={staticonsvg} alt="info" /></div>
                  <div className="claim-stat-term-txt">
                      *Subject to terms and conditions, including exclusions, of the Vehicle Service Contract. Estimated repair costs are for illustrative purposes only 
and are based on industry averages. Actual repair costs will depend on the vehicle make and model, type of breakdown, and other particular 
circumstances. Terms, conditions and coverages are subject to change at any time. Not all coverages are available in all areas and coverage 
may vary by state.
                  </div>
              </div>
          </div>
        </section>

        <section className="process-work-container extra-benefits benifitsSection">
          <div className="container">
            <h3 className="title">A Better Choice</h3>
            <p className="subtitle">Take advantage of these exclusive benefits from servicecontract.com.</p>
            <p className="subtitle2">Our team is there for you during any breakdown and throughout the claims process.</p>
            <div className="work-content">
              <div className="card">
                <div className="header">
                  <span> <img src={icon8} alt="Logo" /></span>
                </div>
                <p>Unlike others, our coverages are offered on a monthly basis so you’re not locked in! You can also easily modify your coverage.</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon9} alt="Logo" /></span>
                </div>
                <p>You choose the deductible that best fits your budget.</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon6} alt="Logo" /></span>
                </div>
                <p>Repairs are handled by your trusted dealer’s repair shop. If you’re away from home, we’ve got you covered with reputable shops nationwide.</p>
              </div>
              <div className="card">
                <div className="header">
                  <span> <img src={icon7} alt="Logo" /></span>
                </div>
                <p>Roadside assistance is provided in the event you get stuck on the side of the road.</p>
              </div>
              <div className="card last">
                <div className="header">
                  <span> <img src={icon10} alt="Logo" /></span>
                </div>
                <p>If your car needs time in the shop, we’ll take care of a rental car<sup className="small-1">#1</sup> to keep you on the road.</p>
              </div>
            </div>
            {/* <div className="slider-point">
              <span className="active"></span>
              <span></span>
              <span></span>
            </div> */}
            <div id="Stacks"></div>
            <div className="btn-container">
              {/* <button className="btn get-price-btn">Get Your Price</button> */}
              <Link className="btn primary get-price-btn" to="/vehicleInfo">Get Your Quote</Link>
            </div>
            
          </div>

        </section>
        
        <PopupStacks />
        {/* <NewsLatter /> */}
        <section className="contact-container">
          <div className="container">
            <div className="copyright">
              <p>#1 If your vehicle experiences a breakdown and is inoperable or unsafe to drive and needs to be held for at least one day by a repair facility for a covered repair, we will reimburse you the cost to rent a vehicle from a licensed rental agency at a rate not to exceed thirty-five dollars ($35.00) per day.  Rental reimbursement is limited to a maximum of five (5) days per breakdown of the same covered part and an aggregate maximum of thirty (30) days as limited by the rental reimbursement maximum.</p>
              <p>#2 Subject to the terms and conditions of the plan.</p>
            </div>
          </div>
        </section>
        <div className="break-section">
          <img src={lineImage} alt="line" />
        </div>
        <Footer />
      </Layout>
      
    );
  }
}

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionCreators.logout()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);


// export default 
