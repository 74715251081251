import React, { Component } from "react"
import "../assets/css/success.css"
import closeChat from "../images/close-icon.svg"
import car from "../images/car.svg"
class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false
        };
    }

    render() {
        const { showPopup } = this.state;
        return (
            <div className="insured-text"><b>Does your vehicle still have factory warranty? </b>
            <span onClick={() => this.setState({ showPopup: true })}>Click here to compare how much you’ll save by purchasing now.</span>

            {showPopup ?
                <div className="success-container factory">
                    <div className="success-inner medium">
                        <div onClick={() => this.setState({ showPopup: false })} className="close-btn close">
                            <img className="" src={closeChat} alt="" />
                        </div>
                        <div className="content">
                            <h2 className="head">How much can you save by purchasing now?</h2>
                            <div className="t-center car"><img className="" src={car} alt="" /></div>
                            <div className="fcolor t-center fsubHead">Does your vehicle still have factory warranty?</div>
                            <div className="fbox">
                                <div className="half">
                                <div className="fcolor yes">Yes</div>
                                <div className="fbox-text">You have a 2018 Honda Civic with factory warranty remaining and you select the Almost Comprehensive coverage level. Your monthly payment will be $41.</div>
                                </div>
                                <div className="half">
                                <div className="fcolor no">No</div>
                                <div className="fbox-text">You have the same 2018 Honda Civic, but the mileage has exceeded the factory warranty limitation.  Your monthly payment will be $50.</div>
                                </div>
                            </div>
                            <div className="m-t20 ftxt-bottom">That’s a difference of $9/month. Over 36 consecutive payments, you will pay an additional $324 just because you waited too long!</div>
                            <div onClick={() => this.setState({ showPopup: false })} className="button">
                                <button className="btn">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                :''}
            </div>
        );
    }
}



export default Popup;
